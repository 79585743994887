import { sendRequest, triggerEvent } from './global';
import { isNumeric } from './util';
import { capitalizeWords } from './string';

export const modelSeparator = '|';
export const othersModelSeparator = '::';

export const csvColumns = [
  'Company Name',
  'Company URL',
  'HQ Location',
  'Company Type',
  'SDG',
  'Market Map Name',
  'Chain Position',
  'Value Chain Category',
  'Company Type',
  'Detailed Description',
  'Funding Round',
  'Funding Amount',
  'Go to Market Model',
  'Business Model',
  'Upload Logo',
];

export const loadOptions = (route, setter, additionalParams = {}) => {
  let params = '';
  let textField = '';
  let key = 'id';
  switch (route) {
    case 'company-types':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      break;
    case 'goals':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      break;
    case 'market-maps':
      params = {
        order: 'name',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      key = 'slug';
      break;
    case 'value-chains':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      break;
    case 'value-chain-types':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
    case 'market-map-experts':
      params = {
        order: 'name',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      break;
    default:
      break;
  }
  sendRequest({
    node: true,
    noLoad: true,
    method: route,
    type: 'GET',
    data: params,
    success: (data) => {
      const options = data.objects.map((obj) => {
        let text;
        if (route === 'goals') {
          text = capitalizeWords(obj[textField]);
        } else {
          text = obj[textField];
        }
        return {
          key: obj[key],
          text,
          value: +obj.id,
          data: obj,
        };
      });
      setter(options);
    },
    error: (err) => {
      if (err) {
        triggerEvent('showSnackbar', [{ text: err, type: 'error' }]);
      }
    },
  });
};

export const formatCsvParsedDataToFormData = (csvData, initialOptions) => {
  const {
    goals: goalOptions,
    market_maps,
    value_chains,
    value_chain_types,
    company_types,
  } = initialOptions;

  return csvData.map((row) => {
    const goal = goalOptions.find(
      (option) =>
        option.text.toLowerCase() === row['SDG'].toLowerCase() ||
        (isNumeric(row['SDG']) && +row['SDG'] === +option.value)
    );
    const marketMap = market_maps
      ? market_maps.find(
          (option) =>
            option.text.toLowerCase() === row['Market Map Name'].toLowerCase()
        )
      : null;
    const valueChain = value_chains
      ? value_chains.find(
          (option) =>
            option.text.toLowerCase() === row['Chain Position'].toLowerCase()
        )
      : null;
    const valueChainType = value_chain_types
      ? value_chain_types.find(
          (option) =>
            option.text.toLowerCase() ===
            row['Value Chain Category'].toLowerCase()
        )
      : null;
    const companyType = company_types
      ? company_types.find(
          (option) =>
            option.text.toLowerCase() === row['Company Type'].toLowerCase()
        )
      : null;

    // Format market models
    let market_models = [];
    let other_market_model = '';
    if (row['Go to Market Model']) {
      market_models = row['Go to Market Model']
        .split(modelSeparator)
        .map((value) => {
          if (value.includes('others')) {
            const otherModelArr = value.split(othersModelSeparator);
            other_market_model = otherModelArr[1];
            return 'others';
          } else {
            return value.toLowerCase();
          }
        });
    }

    // Format business models
    let business_models = [];
    let other_business_model = '';
    if (row['Business Model']) {
      business_models = row['Business Model']
        .split(modelSeparator)
        .map((value) => {
          if (value.includes('others')) {
            const otherModelArr = value.split(othersModelSeparator);
            other_business_model = otherModelArr[1];
            return 'others';
          } else {
            return value.toLowerCase();
          }
        });
    }

    return {
      name: row['Company Name'],
      url: row['Company URL'],
      location: row['HQ Location'],
      company_type_id: companyType ? companyType.value : null,
      company_type_name: companyType ? companyType.text : null,
      goal_id: goal ? goal.value : null,
      goal_name: goal ? goal.text : null,
      market_map_id: marketMap ? marketMap.value : null,
      market_map_name: marketMap ? marketMap.text : null,
      value_chain_id: valueChain ? valueChain.value : null,
      value_chain_name: valueChain ? valueChain.text : null,
      value_chain_type_id: valueChainType ? valueChainType.value : null,
      value_chain_type_name: valueChainType ? valueChainType.text : null,
      description: row['Detailed Description'],
      funding_round: row['Funding Round'],
      funding_amount: row['Funding Amount'],
      market_models,
      other_market_model,
      business_models,
      other_business_model,
      logo_base64: '',
      logo_filename: '',
      logo_url: row['Upload Logo'],
    };
  });
};
