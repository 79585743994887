export default {
  section: 'Data',
  config: {
    method: 'experts/requests/',
    title: 'Experts Requests',
    node: true,
    objectName: 'User',
    disableAdd: true,
    search: {
      name: {
        type: 'text',
        title: 'Name',
      },
      email: {
        type: 'text',
        title: 'Email',
      },
    },
  },
  properties: {
    __counter: {
      default: false,
      type: 'counter',
      title: '',
      preset: true,
      width: '60px',
    },
    id: {
      preset: true,
    },
    user_id: {
      default: false,
      type: 'text',
      title: 'User ID',
      preset: true,
      width: '70px',
    },
    name: {
      type: 'text',
      title: 'Name',
      preset: true,
      width: 'auto',
    },
    email: {
      default: '',
      type: 'text',
      title: 'Email',
      preset: true,
      width: 'auto',
    },
    requested_at: {
      default: '',
      type: 'datetime',
      title: 'Requested time',
      preset: true,
      width: 'auto',
    },
    first_name: {
      default: '',
      type: 'text',
      title: 'First name',
      preset: true,
    },
    last_name: {
      default: '',
      type: 'text',
      title: 'Last name',
      preset: true,
    },
    status: {
      type: 'select',
      clearable: true,
      title: 'Expert Status',
      width: 'auto',
      method: 'experts/requests/:id',
      node: true,
      options: [
        {
          id: '',
          key: '',
          name: 'Select Expert Status',
        },
        {
          id: 'approved',
          key: 'Approved',
          name: 'Approved',
        },
        {
          id: 'pending',
          key: 'Pending',
          name: 'Pending',
        },
        {
          id: 'rejected',
          key: 'Rejected',
          name: 'Rejected',
        },
      ],
    },
    lynk_url: {
      default: '',
      type: 'text',
      title: 'Lynk Url',
    },
  },
};
