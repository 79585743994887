import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Segment,
  Form,
  Icon,
  Table,
  Pagination,
  Header,
} from 'semantic-ui-react';

import { sendRequest, triggerEvent } from '../../helpers/global';
import { loadOptions } from '../../helpers/market_map';

const DEFAULT_PAGE_LIMIT = 10;

function MarketMapInformationListView(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [searchFields, setSearchFields] = useState({
    goal_id: null,
    market_map_id: null,
  });
  const [informations, setInformations] = useState([]);
  const [loadingInformations, setLoadingInformations] = useState(false);
  const [paging, setPaging] = useState({
    page: 1,
    totalPages: 0,
  });

  const loadList = (page = 1) => {
    const { goal_id, market_map_id } = searchFields;
    const params = {
      ...(goal_id && { goal_id }),
      ...(market_map_id && { market_map_id }),
      page,
      limit: DEFAULT_PAGE_LIMIT,
    };
    setLoadingInformations(true);
    sendRequest({
      node: true,
      noLoad: true,
      method: 'informations',
      type: 'GET',
      data: params,
      success: (data) => {
        const { count, objects } = data;
        const totalPages = Math.ceil(count / DEFAULT_PAGE_LIMIT);
        setInformations(objects);
        setLoadingInformations(false);
        setPaging({
          ...paging,
          totalPages,
        });
      },
      error: (err) => {
        setLoadingInformations(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnDelete = (id) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to delete this Information?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            sendRequest({
              node: true,
              method: `informations/${id}`,
              type: 'DELETE',
              success: (_) => {
                triggerEvent('showSnackbar', [
                  { text: 'Information deleted', type: 'success' },
                ]);
                loadList();
              },
              error: (err) => {
                triggerEvent('showSnackbar', [
                  { text: err.message, type: 'error' },
                ]);
              },
            });
          }
        },
      },
    ]);
  };

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadOptions('market-maps', setOptionsMarketMap);
    loadList();
  }, []);

  return (
    <Container>
      <Header as="h2">Informations</Header>
      <Segment>
        <Form>
          <Form.Group>
            <Form.Select
              label="SDG"
              placeholder="Select SDG"
              options={optionsGoal}
              clearable
              search
              value={searchFields.goal_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  goal_id: value,
                  market_map_id: null,
                });
                loadOptions('market-maps', setOptionsMarketMap, {
                  goal_id: value,
                });
              }}
              width={5}
            />
            <Form.Select
              label="Market Map"
              placeholder="Select Market Map"
              options={optionsMarketMap}
              clearable
              search
              value={searchFields.market_map_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  market_map_id: value,
                });
              }}
              width={5}
            />
            <Form.Button
              positive
              onClick={() => loadList()}
              style={{ marginTop: 25 }}
            >
              Search
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
      <Segment loading={loadingInformations}>
        <Table singleLine fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>SDG</Table.HeaderCell>
              <Table.HeaderCell>Market Map Name</Table.HeaderCell>
              <Table.HeaderCell width={4}>Summary</Table.HeaderCell>
              <Table.HeaderCell width={4}>Glossary</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!loadingInformations && informations.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="5">
                  No Market Map Informations found
                </Table.Cell>
              </Table.Row>
            )}
            {!loadingInformations &&
              informations.length > 0 &&
              informations.map((information) => (
                <Table.Row key={information.id}>
                  <Table.Cell>
                    <Link to={`/categories/${information.goal.id}`}>
                      {information.goal.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/market-maps/${information.market_map.id}`}>
                      {information.market_map.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      dangerouslySetInnerHTML={{ __html: information.summary }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      dangerouslySetInnerHTML={{ __html: information.glossary }}
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Icon
                      link
                      name="pencil"
                      onClick={() =>
                        props.history.push(
                          `/market-map-informations/${information.id}`
                        )
                      }
                    />
                    <Icon
                      link
                      name="trash"
                      onClick={() => handleOnDelete(information.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <Pagination
                  defaultActivePage={1}
                  siblingRange={2}
                  totalPages={paging.totalPages}
                  onPageChange={(e, { activePage }) => loadList(activePage)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Link to={`/market-map-informations/create`}>
        <div className="createButton">
          <span className="material-icons">add</span>
        </div>
      </Link>
    </Container>
  );
}

export default withRouter(MarketMapInformationListView);
