import React from 'react';
import classnames from 'classnames';
import Datetime from 'react-datetime';
import moment from 'moment';

import '../../sass/components/input/DateInput.scss';

export default class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    let object = e.unix();
    this.setState({ object });
    this.props.onChange(this.props.objectKey, object);
  };

  render = () => {
    const properties = this.props.properties;
    const object =
      typeof this.props.object === 'number'
        ? moment(this.props.object * 1000)
        : moment(this.props.object);
    return (
      <div
        className={classnames({
          dateInput: true,
          simplified: properties.simplified,
        })}
      >
        <Datetime
          value={object}
          onChange={this.handleChange}
          inputProps={{
            disabled: this.props.disabled,
            placeholder: this.props.placeholder,
          }}
          dateFormat="DD.MM.YYYY"
          timeFormat={properties.type === 'datetime' ? 'HH:mm' : false}
          closeOnSelect={true}
        />
      </div>
    );
  };
}
