import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import '../../sass/components/input/WysiwygInput.scss';

class WysiwygInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.parseValue(props.object),
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.object && !prevProps.object) {
      this.setState({
        value: this.parseValue(this.props.object),
      });
    }
  };

  parseValue = (value) => {
    if (!value) {
      return EditorState.createEmpty();
    }
    if (typeof value === 'string') {
      return htmlToState(value);
    } else {
      return value;
    }
  };

  handleChange = (editorState) => {
    this.setState({ value: editorState });
    this.props.onChange(this.props.objectKey, editorState);
  };

  render = () => {
    const config = this.props.config || {};

    return (
      <div className="wysiwygInput">
        <Editor
          editorState={this.state.value}
          toolbar={
            this.props.toolbar
              ? this.props.toolbar
              : {
                  options: ['inline', 'link', 'list'],
                  inline: {
                    options: ['bold', 'italic', 'strikethrough'],
                  },
                  link: {
                    options: ['link'],
                  },
                  list: {
                    options: ['unordered', 'ordered'],
                  },
                }
          }
          readOnly={this.props.disabled}
          wrapperClassName="wysiwygWrapper"
          toolbarClassName="wysiwygToolbar"
          editorClassName="wysiwygEditor"
          placeholder={config.placeholder}
          onEditorStateChange={this.handleChange}
        />
      </div>
    );
  };
}

export default WysiwygInput;

export const stateToHtml = (state) => {
  if (typeof state === 'string') {
    return state;
  } else if (state) {
    return draftToHtml(convertToRaw(state.getCurrentContent()));
  } else {
    return '';
  }
};

export const htmlToState = (html) => {
  if (!html || html.length === 0) {
    return EditorState.createEmpty();
  }
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  } else {
    return EditorState.createEmpty();
  }
};
