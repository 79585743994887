export default {
  section: 'Data',
  config: {
    method: 'experts/',
    node: true,
    title: 'Experts',
    objectName: 'Experts',
    search: {
      name: {
        type: 'text',
        title: 'Name',
      },
      email: {
        type: 'text',
        title: 'Email',
      },
    },
  },
  properties: {
    __counter: {
      default: false,
      type: 'counter',
      title: '',
      preset: true,
      width: '60px',
    },
    id: {
      preset: true,
    },
    user_id: {
      default: false,
      type: 'text',
      title: 'User ID',
      preset: true,
      width: '70px',
    },
    name: {
      type: 'text',
      title: 'Name',
      preset: true,
      width: 'auto',
    },
    email: {
      default: '',
      type: 'text',
      title: 'Email',
      preset: true,
      width: 'auto',
    },
    requested_at: {
      default: '',
      type: 'datetime',
      title: 'Requested time',
      preset: true,
      width: 'auto',
    },
    approved_at: {
      default: '',
      type: 'datetime',
      title: 'Approved time',
      preset: true,
      width: 'auto',
    },
    first_name: {
      default: '',
      type: 'text',
      title: 'First name',
      preset: true,
    },
    last_name: {
      default: '',
      type: 'text',
      title: 'Last name',
      preset: true,
    },
    lynk_url: {
      default: '',
      type: 'text',
      title: 'Lynk Url',
    },
  },
};
