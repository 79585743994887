export default {
  section: "Data",
  config: {
    method: "users/",
    title: "Users",
    objectName: "User",
    search: {
      name: {
        type: "text",
        title: "Name",
      },
      email: {
        type: "text",
        title: "Email",
      },
      country_id: {
        type: "select",
        title: "Location",
        request: "common_data/countries",
        clearable: true,
      },
    user_status: {
      type: "select",
      title: "User Status",
      // preset: true,
      options: [
        { id: true, name: 'Active User' },
        { id: false, name: 'Hidden User' }
      ],
    },
    },
  },
  properties: {
    image_url: {
      type: "avatar",
      title: "",
      preset: true,
      width: "50px",
    },
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at_timestamp: {
      default: "",
      type: "datetime",
      title: "Created time",
      width: "auto",
      showCondition: () => false,
    },
    name: {
      type: "text",
      title: "Name",
      preset: true,
      width: "auto",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
      width: "auto",
    },
    password: {
      type: "password",
      title: "Password",
    },
    image: {
      type: "image",
      title: "Image",
    },
    first_name: {
      default: "",
      type: "text",
      title: "First name",
    },
    last_name: {
      default: "",
      type: "text",
      title: "Last name",
    },
    description: {
      default: "",
      type: "text",
      title: "Tagline",
    },
    website: {
      default: "",
      type: "text",
      title: "Website",
    },
    bio: {
      default: "",
      type: "textarea",
      title: "Bio",
    },
    slug: {
      default: "",
      type: "text",
      title: "Slug",
    },
    admin: {
      type: "checkbox",
      title: "Admin",
      width: "80px",
    },
    user_status: {
      default: "",
      type: "select",
      title: "User Status",
      // preset: true,
      options: [
        { 
          id: true,
          key: true,
          name: 'Active User' },
        {
           id: false,
           key: false,
          name: 'Hidden User' }
      ],
    },
    archive: {
      default: "",
      type: "select",
      title: "User archive",
      // preset: true,
      options: [
        { 
          id: true,
          key: true,
          name: 'Archive User' },
        {
           id: false,
           key: false,
          name: 'Unarchive User' }
      ],
    },
    chats: {
      default: [],
      type: "user-chats",
      title: "Chats",
      side: "user",
      showCondition: object => !!(object.chats || []).length,
    },
    twitter_url: {
      default: "",
      type: "text",
      title: "Twitter Url",
    },
    linkedin_url: {
      default: "",
      type: "text",
      title: "Linkedin Url",
    },
    facebook_url: {
      default: "",
      type: "text",
      title: "Facebook Url",
    },
    instagram_url: {
      default: "",
      type: "text",
      title: "Instagram Url",
    },
    profile_type: {
      type: "select",
      title: "Profile Type",
      clearable: true,
      width: "auto",
      method: "users/:id",
      options:  [
        {
          id: '',
          key: '',
          name: 'Select Profile Type',
        },
        {
          id: 'Investor',
          key: 'Investor',
          name: 'Investor',
        },
        {
          id: 'Startup',
          key: 'Startup',
          name: 'Startup',
        },

        {
          id: 'Corporate',
          key: 'Corporate',
          name: 'Corporate',
        },
        {
          id: 'NGO',
          key: 'NGO',
          name: 'NGO',
        },
        {
          id: 'Academia',
          key: 'Academia',
          name: 'Academia',
        },
        {
          id: 'Government',
          key: 'Government',
          name: 'Government',
        },
        {
          id: 'Accelerator',
          key: 'Accelerator',
          name: 'Accelerator',
        },
        {
          id: 'Consultant',
          key: 'Consultant',
          name: 'Consultant',
        },
        {
          id: 'Others',
          key: 'Others',
          name: 'Others',
        }
      ]
    },
    country_id: {
      type: "select",
      title: "Location",
      request: "common_data/countries",
      clearable: true,
    },
    goal_ids: {
      type: "multi-select",
      title: "Goals",
      request: "common_data/goals",
    },
    objective_ids: {
      type: "multi-select",
      title: "Objectives",
      searchKey: "title",
      request: "common_data/objectives",
    },
  }
};
