import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Icon,
  Header,
  Modal,
  Button,
  Segment,
  Table,
  Grid,
  Progress,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { omit } from 'ramda';

import { modelSeparator, othersModelSeparator } from '../../helpers/market_map';
import { triggerEvent, sendRequest } from '../../helpers/global';
import Storage from '../../helpers/Storage';
// import { delay } from '../../helpers/util';
import CompanyForm, { RemoteSubmit } from './shared/CompanyForm';

const BackContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    font-weight: bold;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

function MarketMapCompanyBulkUploadView(props) {
  const [uploadCompanies, setUploadCompanies] = useState([]);
  const [openCompanyFormModal, setOpenCompanyFormModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [rowIndex, setRowIndex] = useState(-1);
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [progress, setProgress] = useState({
    total: 0,
    value: 0,
    label: '',
    error: true,
    success: true,
  });
  const [notSavedRowIndexes, setNotSavedRowIndexes] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleCloseModal = () => {
    setOpenCompanyFormModal(false);
    setSelectedCompany(null);
    setRowIndex(-1);
    setIsSubmit(false);
  };

  const handleSelectCompany = (company, index) => {
    setSelectedCompany(company);
    setRowIndex(index);
    setIsSubmit(false);
  };

  const handleSubmitUpdateRow = (values) => {
    const newUploadCompanies = uploadCompanies.map((company, index) =>
      rowIndex === index
        ? {
            ...company,
            ...values,
          }
        : company
    );
    setUploadCompanies(newUploadCompanies);
    handleCloseModal();
  };

  const handleDeleteRow = (index) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to delete this row?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            const newUploadCompanies = [
              ...uploadCompanies.slice(0, index),
              ...uploadCompanies.slice(index + 1),
            ];
            setUploadCompanies(newUploadCompanies);
          }
        },
      },
    ]);
  };

  const saveCompany = (values) => {
    return new Promise((resolve) => {
      const market_models = values.market_models
        .map((value) =>
          value === 'others'
            ? [value, values.other_market_model].join(othersModelSeparator)
            : value
        )
        .join(modelSeparator);
      const business_models = values.business_models
        .map((value) =>
          value === 'others'
            ? [value, values.other_business_model].join(othersModelSeparator)
            : value
        )
        .join(modelSeparator);

      sendRequest({
        noLoad: true,
        node: true,
        method: 'companies',
        type: 'POST',
        data: {
          ...omit(
            [
              'goal_id',
              'other_market_model',
              'other_business_model',
              'value_chain_id',
              'company_type_name',
              'goal_name',
              'market_map_name',
              'value_chain_name',
              'value_chain_type_name',
            ],
            values
          ),
          market_models,
          business_models,
        },
        success: (data) => {
          // triggerEvent('showSnackbar', [
          //   { text: 'Company saved', type: 'success' },
          // ]);
          // props.history.push('/market-map-companies');
          resolve(true);
        },
        error: (err) => {
          // triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
          resolve(false);
        },
      });
    });
  };

  const handleSubmitAll = () => {
    const saveAll = async () => {
      const toSaveUploadCompanies = [...uploadCompanies];
      const totalCompanies = toSaveUploadCompanies.length;
      const indexesNotSaved = [];

      for (let i = 0; i < totalCompanies; i++) {
        const values = { ...toSaveUploadCompanies[i] };

        setProgress({
          total: totalCompanies,
          value: i + 1,
          error: false,
          success: false,
          label: `Saving company: ${values.name}...`,
        });

        const result = await saveCompany(values);
        setProgress({
          total: totalCompanies,
          value: i + 1,
          error: !result,
          success: result,
          label: result
            ? `Succesfully saved company: ${values.name}`
            : `Error saving company: ${values.name}`,
        });
      }

      setOpenProgressModal(false);

      if (!indexesNotSaved.length) {
        triggerEvent('showSnackbar', [
          { text: 'All Companies saved', type: 'success' },
        ]);
        setUploadCompanies([]);
      } else {
        triggerEvent('showSnackbar', [
          { text: 'Some Companies are not saved', type: 'success' },
        ]);
        setNotSavedRowIndexes(indexesNotSaved);
      }
    };

    triggerEvent('showConfirmation', [
      {
        title: `Are you sure to add these companies?`,
        confirmText: 'Save',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            setNotSavedRowIndexes([]);
            setOpenProgressModal(true);
            saveAll();
          }
        },
      },
    ]);
  };

  const handleRemoteSubmitForm = () => {
    setIsSubmit(true);
  };

  useEffect(() => {
    const companies = Storage.getUploadCompanies();
    setUploadCompanies(companies ? [...companies] : []);
    // return () => {
    //   Storage.clearUploadCompanies();
    // };
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      setOpenCompanyFormModal(true);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (notSavedRowIndexes.length) {
      const newUploadCompanies = uploadCompanies.filter((_, index) =>
        notSavedRowIndexes.includes(index)
      );
      setUploadCompanies(newUploadCompanies);
    }
  }, [notSavedRowIndexes]);

  return (
    <Container fluid>
      <Grid>
        <Grid.Column width={8}>
          <Header as="h2">Bulk Upload Companies</Header>
        </Grid.Column>
        <Grid.Column width={8}>
          <BackContainer>
            <Link to="/market-map-companies">← Back to Company List</Link>
          </BackContainer>
        </Grid.Column>
      </Grid>
      <Segment>
        {!!uploadCompanies.length && (
          <Button positive onClick={() => handleSubmitAll()}>
            Save All
          </Button>
        )}

        <Table size="small" compact fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Company Name</Table.HeaderCell>
              <Table.HeaderCell width={2}>Company URL</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>SDG</Table.HeaderCell>
              <Table.HeaderCell>Market Map Name</Table.HeaderCell>
              <Table.HeaderCell>Chain Position</Table.HeaderCell>
              <Table.HeaderCell>Value Chain Category</Table.HeaderCell>
              <Table.HeaderCell>Company Type</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!uploadCompanies.length && (
              <Table.Row>
                <Table.Cell colSpan="9">
                  No Companies for Bulk Upload, please return to the{' '}
                  <Link to="/market-map-companies">Company List</Link>
                </Table.Cell>
              </Table.Row>
            )}
            {uploadCompanies.length > 0 &&
              uploadCompanies.map((company, index) => (
                <Table.Row key={`${index}-${company.name ?? 'empty'}`}>
                  <Table.Cell>{company.name ?? ''}</Table.Cell>
                  <Table.Cell singleLine>{company.url ?? ''}</Table.Cell>
                  <Table.Cell>{company.location ?? ''}</Table.Cell>
                  <Table.Cell>{company.goal_name ?? ''}</Table.Cell>
                  <Table.Cell>{company.market_map_name ?? ''}</Table.Cell>
                  <Table.Cell>{company.value_chain_name ?? ''}</Table.Cell>
                  <Table.Cell>{company.value_chain_type_name ?? ''}</Table.Cell>
                  <Table.Cell>{company.company_type_name ?? ''}</Table.Cell>
                  <Table.Cell width={1}>
                    <Icon
                      link
                      name="pencil"
                      onClick={() => handleSelectCompany(company, index)}
                    />
                    <Icon
                      link
                      name="trash"
                      onClick={() => handleDeleteRow(index)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        {!!uploadCompanies.length && (
          <Button positive onClick={() => handleSubmitAll()}>
            Save All
          </Button>
        )}
      </Segment>
      <Modal
        onClose={() => handleCloseModal()}
        onOpen={() => setOpenCompanyFormModal(true)}
        open={openCompanyFormModal}
        size="large"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Header size="medium">Upload Company</Header>
        <Modal.Content scrolling>
          {selectedCompany && (
            <CompanyForm
              formValues={selectedCompany}
              onSubmitHandler={handleSubmitUpdateRow}
              renderRemoteSubmit={() => (
                <RemoteSubmit submit={isSubmit} setSubmit={setIsSubmit} />
              )}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Close</Button>
          <Button positive onClick={() => handleRemoteSubmitForm()}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={openProgressModal} closeOnDimmerClick={false} size="small">
        <Header size="medium">Uploading Companies</Header>
        <Modal.Content>
          <Progress
            progress="ratio"
            value={progress.value}
            total={progress.total}
            error={progress.error}
            success={progress.success}
          >
            {progress.label}
          </Progress>
        </Modal.Content>
      </Modal>
    </Container>
  );
}

export default withRouter(MarketMapCompanyBulkUploadView);
