export default {
  // root
  // dashboard: { rootSection: true, config: { title: "Dashboard", adminOnly: true } },

  // Data
  users: require('./users.js').default,
  business_profiles: require('./business_profiles.js').default,
  categories: require('./categories.js').default,
  posts: require('./posts.js').default,
  comments: require('./comments.js').default,
  solutions: require('./solutions.js').default,
  banners: require('./banners.js').default,
  subcategories: require('./subcategories.js').default,
  claim_profiles: require('./claim_profiles.js').default,

  experts: require('./experts.js').default,
  'experts-requests': require('./experts-requests.js').default,

  // Market Maps
  'market-maps': require('./market_map/market_maps.js').default,
  'market-map-categories': require('./market_map/categories.js').default,
  'market-map-companies': require('./market_map/companies.js').default,
  'market-map-informations': require('./market_map/informations.js').default,
  'market-map-requests': require('./market_map/market_map_requests.js').default,
  'market-map-company-requests': require('./market_map/company_requests.js')
    .default,

  // Logs
  audit_logs: require('./audit_logs.js').default,
};
